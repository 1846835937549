import { Component, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {  LazyLoadEvent } from "primeng/api";
import { Dropdown } from "primeng/dropdown";
import { of } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { AppGlobals } from "src/app/app.globals";
import { AppBreadcrumbService } from "src/app/core/breadcumb/app.breadcrumb.service";
import { ServiceInstance } from "../instances/domain/instance";
import { InstanceService } from "../instances/service/instanceservice";
import { Product } from "./domain/product";
import { ProductDetailService } from "./service/productDetail.service";
import { ExportFileType } from "src/app/enum/exportFileType.enum";


@Component({
    templateUrl: "./productDetail.component.html",
    styleUrls: ["./productDetail.component.scss"],
})


export class ProductDetailComponent {
    private token: string;
    public productList: Product[];
    public clientsList: Document[] = [];
    public serviceInstanceList: ServiceInstance[];
    public serviceInstancesFilter: ServiceInstance[];
    public productListLoading: boolean = true;
    public productExportLoading: boolean = false; 
    public productData: Product;
    public exportFileType = ExportFileType;
    public warningModal: boolean;
    public totalRecords: number;
    public productId: string;
    event_lazy_local: LazyLoadEvent;
    itemsPerPage: number = 5;

    @ViewChild("dropdown_serviceIstances")
    dropdown_serviceIstances: Dropdown;

    constructor(
        private breadcrumbService: AppBreadcrumbService,
        private route: ActivatedRoute,
        private productService: ProductDetailService) {
        let context = this;
        context.token = sessionStorage.getItem('alkenium_Token').toString();
        context.breadcrumbService.setItems([AppGlobals.setBreadCrumb('Collection Plan Products', '/collectionplans', AppGlobals.userHasPermission("IS_CLIENT"))]);
        context.event_lazy_local = {} as LazyLoadEvent;
        context.productId = this.route.snapshot.paramMap.get("pid");
        this.getProductData(context.productId);
    }

    public getProductData(collectionId:string)
    {
        let context = this;
        context.productService.getProductData(context.token, null, context.productId)
            .pipe(
                tap((res) => {
                    if(res.data != null)
                    {
                        context.productData = res.data;
                    }
                }),
                catchError(() => {
                    return of();
                })
            )
            .subscribe();
    }

    public userHasPermission(permission: string){
        return (permission != '' && permission != null ? (!AppGlobals.userHasPermission(permission) ? false : true) : true);
    }
}