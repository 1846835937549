import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, LazyLoadEvent, MessageService } from 'primeng/api';
import { AppBreadcrumbService } from 'src/app/core/breadcumb/app.breadcrumb.service';
import { catchError, finalize, map, tap } from "rxjs/operators";
import { of } from 'rxjs';
import { Group } from './domain/group';
import { GroupService } from './service/groupservice';
import { NgForm } from '@angular/forms';
import { ServiceInstance } from '../instances/domain/instance';
import { InstanceService } from '../instances/service/instanceservice';
import { TreeNode } from 'primeng/api';
import { Permission } from '../permissions/domain/permission';
import { TreenNodes } from "src/app/utilities/treenodes";
import { PermissionService } from '../permissions/service/permissionservice';
import { AppGlobals } from 'src/app/app.globals';
@Component({
    templateUrl: './group.component.html',
    styleUrls: ['./group.component.scss']
})

export class GroupComponent {
    private token: string;
    public group_new: Group = {};
    public group_edit: Group = {};
    public groupsList: Group[];
    public serviceInstanceList: ServiceInstance[];

    public groupPermissionId: string;

    public permissionsNodes: TreeNode[];
    public permissionsNodesSelected: TreeNode[];
    public permissionsNodesUnselected: TreeNode[];

    public permissionsList: Permission[];
    public permissionsGroupList: Permission[];
    public permissionstrings: string[];


    public groupCreateModal: boolean = false;
    public groupEditModal: boolean = false;
    public groupsListLoading: boolean = true;
    public totalRecords: number;
    public permissionModal: boolean = false;
    public treenodes: TreenNodes;

    event_lazy_local: LazyLoadEvent;
    @ViewChild("createGroupForm")
    createGroupForm: NgForm;

    itemsPerPage: number = 5;

    constructor(
        private breadcrumbService: AppBreadcrumbService,
        private groupService: GroupService,
        private messageService: MessageService,
        private serviceInstanceService: InstanceService,
        private confirmationService: ConfirmationService,
        public permissionService: PermissionService) {
            
        this.breadcrumbService.setItems([
            AppGlobals.setBreadCrumb('Groups', '/users/groups', AppGlobals.userHasPermission("IS_CLIENT"))
        ]);
        this.token = sessionStorage.getItem('alkenium_Token').toString();
        this.event_lazy_local = {} as LazyLoadEvent;
        this.treenodes = new TreenNodes();

        this.permissionsNodesSelected = [] as TreeNode[];
        this.permissionsNodesUnselected = [] as TreeNode[];

        this.setServiceInstancesDropdown();
    }

    checkNode(nodes: TreeNode[], str: string[]) {
        for (let i = 0; i < nodes.length; i++) {
            if (!nodes[i]?.leaf && nodes[i].children[0]?.leaf) {
                for (let j = 0; j < nodes[i].children.length; j++) {
                    if (str.includes(nodes[i].children[j].data)) {
                        if (!this.permissionsNodesSelected.includes(nodes[i].children[j])) {
                            this.permissionsNodesSelected.push(nodes[i].children[j]);
                        }
                    }
                }
            }
            if (nodes[i]?.leaf) {
                return;
            }
            this.checkNode(nodes[i]?.children, str);
            let lengthChildren = nodes[i]?.children.length;
            let countPartial = 0;
            for (let j = 0; j < nodes[i]?.children.length; j++) {
                if (this.permissionsNodesSelected.includes(nodes[i]?.children[j])) {
                    countPartial++;
                }
                if (nodes[i]?.children[j].partialSelected) nodes[i].partialSelected = true;
            }
            if (countPartial == 0) { }
            else if (countPartial == lengthChildren) {
                nodes[i].partialSelected = false;
                if (!this.permissionsNodesSelected.includes(nodes[i])) {
                    this.permissionsNodesSelected.push(nodes[i]);
                }
            }
            else {
                nodes[i].partialSelected = true;
            }
        }
    }

    public userHasPermission(permission: string){
        return (permission != '' && permission != null ? (!AppGlobals.userHasPermission(permission) ? false : true) : true);
    }

    setServiceInstancesDropdown() {
        this.serviceInstanceService.getServiceInstanceList(this.token, null, null, null)
            .pipe(
                tap((res) => {
                    if (res.data != null) {
                        this.serviceInstanceList = res.data.map((service) => {
                            return {
                                id: service.id,
                                name: service.name
                            }
                        });
                    }
                }),
                catchError((res) => {
                    return of();
                })
            ).subscribe();
    }

    getPermissionsList() {
        this.permissionService.getListPermission(this.token)
            .pipe(
                tap((res) => {
                    if (res.data != null) {
                        this.permissionsList = res.data.map((permission) => {
                            return {
                                module: permission.module,
                                action: permission.actions
                            }
                        });
                        console.log(this.permissionsList);

                        let jsonModel = this.treenodes.transformData(this.permissionsList);

                        this.permissionsNodes = <TreeNode[]>JSON.parse(jsonModel);
                    }
                }),
                catchError(() => {
                    return of();
                })
            )
            .subscribe();
    }

    selectNode($event) {
        if (this.permissionsNodesUnselected.length > 0) {
            let i = 0;
            this.permissionsNodesUnselected.forEach(node => {
                if (node.data == $event.node.data) {
                    this.permissionsNodesUnselected.splice(i, 1);
                    return;
                }
                i++;
            });
        }
    }

    unselectNode($event) {
        this.permissionsNodesUnselected.push($event.node);
    }

    getList(event: LazyLoadEvent) {
        if (event != null)
            this.event_lazy_local = event;

        this.groupsListLoading = true;
        this.groupService.getGroupsList(this.token, this.event_lazy_local, null)
            .pipe(
                tap((res) => {
                    if (res.data != null) {
                        let final = res.data.slice(this.event_lazy_local.first, (this.event_lazy_local.first + this.event_lazy_local.rows))
                        this.totalRecords = res.data.length;
                        this.groupsList = final.map((group) => {
                            return {
                                id: group.id,
                                name: group.name,
                                serviceInstance: group.serviceInstanceName,
                                serviceInstanceId: group.serviceInstanceId
                            }
                        });
                    }

                }),
                catchError((res) => {
                    return of();
                }),
                finalize(() => {
                    this.groupsListLoading = false;
                })
            )
            .subscribe();
    }

    permissionsGroup(id:string) {
        this.permissionModal = true;
        this.getPermissionsList();
        this.getPermissionsGroup(id);
        this.groupPermissionId = id;
    }

    getPermissionsGroup(id: string) {
        this.permissionService.getListGroupPermissions(this.token, id)
            .pipe(
                tap((res) => {
                    if (res.data != null) {
                        this.permissionsGroupList = res.data.map((permission) => {
                            return {
                                module: permission.permissionData.module,
                                action: permission.permissionData.action
                            }
                        });
                        this.permissionstrings = [] as string[];
                        this.permissionsGroupList.forEach(perm => {
                            this.permissionstrings.push(perm.action.toString());
                        });
                    }
                }),
                catchError(() => {
                    return of();
                }),
                finalize(() => {
                    this.checkNode(this.permissionsNodes, this.permissionstrings);
                })
            )
            .subscribe();
    }

    closePermissionsGroup() {
        this.permissionModal = false;
        this.permissionsNodes = [] as TreeNode[];
        this.permissionsNodesSelected = [] as TreeNode[];
        this.permissionsNodesUnselected = [] as TreeNode[];
    }

    savePermissions() {
        let selected = [] as string[];
        let unselected = [] as string[];
        let success = false;

        if (this.permissionsNodesSelected.length > 0) {
            this.permissionsNodesSelected.forEach(node => {
                if (node.leaf == true) {
                    selected.push(node.data);
                }
            });

            this.permissionService.setPermissionsCodeGroup(this.token, this.groupPermissionId, selected)
                .pipe(
                    tap((res) => {
                        if (res.data != null) {
                            success = true
                            if (this.permissionsNodesUnselected.length > 0) {
                                this.permissionsNodesUnselected.forEach(node => {
                                    if (node.leaf == true) {
                                        unselected.push(node.data);
                                    }
                                });

                                this.permissionService.unsetPermissionsCodeGroup(this.token, this.groupPermissionId, unselected)
                                    .pipe(
                                        tap((res) => {
                                            if (res.data != null) {
                                                if (success = true) {
                                                    this.messageService.add({
                                                        severity: 'success',
                                                        summary: 'Success',
                                                        detail: res.message ?? 'Success'
                                                    });
                                                    this.closePermissionsGroup();
                                                }
                                            }
                                        }),
                                        catchError((res) => {
                                            this.messageService.add({
                                                severity: 'error',
                                                summary: 'Error',
                                                detail: res.error?.message ?? res.error.title
                                            });
                                            return of();
                                        }),
                                        finalize(() => {

                                        })
                                    )
                                    .subscribe();
                            }
                            else{
                                this.messageService.add({
                                    severity: 'success',
                                    summary: 'Success',
                                    detail: res.message ?? 'Success'
                                });
                                this.closePermissionsGroup();
                            };
                        }
                    }),
                    catchError((res) => {
                        this.messageService.add({
                            severity: 'error',
                            summary: 'Error',
                            detail: res.error?.message ?? res.error.title
                        });
                        return of();
                    }),
                    finalize(() => {

                    })
                )
                .subscribe();
        }
    }

    createGroup() {
        this.groupCreateModal = true;
        this.group_new = {} as Group;
    }

    closeCreateGroupModal() {
        this.groupCreateModal = false;
        this.createGroupForm.resetForm();
        this.group_new = {} as Group;
    }

    saveGroup() {
        this.groupService.createGroup(this.token, this.group_new)
            .pipe(
                tap((res) => {
                    this.messageService.add({
                        severity: 'success',
                        summary: 'Success',
                        detail: res.message ?? 'Success'
                    });
                    this.closeCreateGroupModal();
                    this.getList(null);
                }),
                catchError((res) => {
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Error',
                        detail: res.error?.message ?? res.error.title
                    });
                    return of();
                }),
                finalize(() => {

                })
            )
            .subscribe();
    }

    editGroup(id: string) {
        this.groupService.getGroup(this.token, id)
            .pipe(
                tap((res) => {
                    if (res.data != null) {
                        this.group_edit = {
                            id: res.data.id,
                            name: res.data.name,
                            description: res.data.description,
                            serviceInstanceId: res.data.serviceInstanceId
                        }
                        this.groupEditModal = true;
                    }
                }),
                catchError((res) => {
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Error',
                        detail: res.error?.message ?? res.error.title
                    });
                    this.groupEditModal = false;
                    return of();
                }),
                finalize(() => { })
            )
            .subscribe();
    }

    closeEditGroupModal() {
        this.groupEditModal = false;
        this.group_edit = {} as Group;
    }

    updateGroup() {
        this.groupService.updateGroup(this.token, this.group_edit)
            .pipe(
                tap((res) => {
                    this.messageService.add({
                        severity: 'success',
                        summary: 'Success',
                        detail: res.message ?? 'Success'
                    });
                    this.closeEditGroupModal();
                    this.getList(null);
                }),
                catchError((res) => {
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Error',
                        detail: res.error?.message ?? res.error.title
                    });
                    return of();
                }),
                finalize(() => {

                })
            )
            .subscribe();
    }

    deleteGroup(id: string, groupname: string) {
        this.confirmationService.confirm({
            message: "You are about to delete '" + groupname + "'. Are you sure?",
            header: "Warning",
            icon: "pi pi-warning",
            acceptLabel: "Yes",
            rejectLabel: "No",
            accept: () => {
                this.groupService.deleteGroup(this.token, id)
                    .pipe(
                        tap((res) => {
                            this.messageService.add({
                                severity: 'success',
                                summary: 'Success',
                                detail: res.message ?? "Success"
                            });
                            this.getList(null);
                        }),
                        catchError((res) => {
                            this.messageService.add({
                                severity: 'error',
                                summary: 'Error',
                                detail: res.error?.message ?? res.error.title
                            });
                            return of();
                        }),
                        finalize(() => {
                        })
                    )
                    .subscribe();
            },
            reject: () => {
                return;
            },
            rejectVisible: true
        });
    }
}