import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ConfirmationService, LazyLoadEvent, MessageService } from 'primeng/api';
import { Dropdown } from 'primeng/dropdown';
import { stringify } from 'querystring';
import { of } from 'rxjs';
import { catchError, finalize, map, tap } from "rxjs/operators";
import { AppGlobals } from 'src/app/app.globals';
import { AppBreadcrumbService } from 'src/app/core/breadcumb/app.breadcrumb.service';
import { ServiceInstance } from '../instances/domain/instance';
import { InstanceService } from '../instances/service/instanceservice';
import { Supplier } from './domain/supplier';
import { SupplierService } from './service/supplierservice';

@Component({
    templateUrl: './supplier.component.html',
    styleUrls: ['./supplier.component.scss']
})


export class SupplierComponent {
    private token: string;
    public supplier_new: Supplier = {};
    public supplier_edit: Supplier = {};
    public supplierList: Supplier[];
    public serviceInstancesFilter: ServiceInstance[];
    public supplierCreateModal: boolean = false;
    public supplierEditModal: boolean = false;
    public supplierListLoading: boolean = true;
    public warningModal: boolean;
    public totalRecords: number;
    event_lazy_local:LazyLoadEvent;
    itemsPerPage: number = 5;

    @ViewChild("dropdown_serviceIstances")
    dropdown_serviceIstances: Dropdown;
    @ViewChild("createSupplierForm")
    createSupplierForm: NgForm;
    @ViewChild("editSupplierForm")
    editSupplierForm: NgForm;

    constructor(
        private breadcrumbService: AppBreadcrumbService,
        private messageService: MessageService,
        private serviceInstaceService: InstanceService,
        private confirmationService: ConfirmationService,
        private supplierService: SupplierService) {
        this.token = sessionStorage.getItem('alkenium_Token').toString();
        this.breadcrumbService.setItems([
            AppGlobals.setBreadCrumb('Supplier', '/suppliers', AppGlobals.userHasPermission("IS_CLIENT"))
        ]);
        this.event_lazy_local = {} as LazyLoadEvent;
        this.setServiceIstanceFilter();
    }

    createSupplier() 
    {
        this.supplier_new = {} as Supplier;
        this.supplierCreateModal = true;
    }

    setServiceIstanceFilter()
    {
        
    }

    editSupplier(id:string)
    {
       this.supplierService.getSupplier(this.token, id)
        .pipe(
            tap((res) => {
                this.supplier_edit = {
                    id: res.data.id,
                    companyName: res.data.companyName,
                    vatNumber: res.data.vatNumber,
                    fiscalCode: res.data.fiscalCode,
                    emailAddress: res.data.emailAddress,
                    phoneNumber: res.data.phoneNumber,
                    pecEmailAddress: res.data.pecEmailAddress,
                    webSiteAddress: res.data.webSiteAddress,
                    address: res.data.address,
                    zipCode: res.data.zipCode,
                    city: res.data.city,
                    district: res.data.district,
                    country: res.data.country,
                    companyDescription: res.data.companyDescription
                } as Supplier;
                this.supplierEditModal = true;
            }),
            catchError((res) => {
                this.messageService.add({
                    severity: "error",
                    summary : "Error",
                    detail: res.error?.message ?? res.error.title
                });
                return of();
            }),
            finalize(() => {

            })
        )
        .subscribe();
    }

    closeCreateSupplier() {
        this.supplierCreateModal = false;
        this.createSupplierForm.resetForm();
        this.supplier_new = {} as Supplier;
    }

    closeEditSupplier()
    {
        this.supplierEditModal = false;
        this.editSupplierForm.resetForm();
        this.supplier_edit = {} as Supplier;
    }

    public userHasPermission(permission: string){
        return (permission != '' && permission != null ? (!AppGlobals.userHasPermission(permission) ? false : true) : true);
    }

    updateSupplier()
    {
        this.supplierService.updateSupplier(this.token, this.supplier_edit)
            .pipe(
                tap((res) => {
                    this.messageService.add({
                        severity:"success",
                        summary:"Success",
                        detail: res.message ?? "Success"
                    });
                    this.closeEditSupplier();
                    this.getList(null);
                }),
                catchError((res) => {
                    this.messageService.add({
                        severity: "error",
                        summary: "Error",
                        detail: res.error?.message ?? res.error.title
                    });
                    return of();
                }),
                finalize(() => {

                })
            )
            .subscribe();
    }

    saveSupplier() {
        this.supplierService.createSupplier(this.token, this.supplier_new)
            .pipe(
                tap((res) => {
                    this.messageService.add({
                        severity: "success",
                        summary: "Success",
                        detail: res.message ?? "Success"
                    });

                    this.closeCreateSupplier();
                    this.getList(null);
                }),
                catchError((res) => {
                    this.messageService.add({
                        severity: "error",
                        summary : "Error",
                        detail: res.error?.message ?? res.error.title
                    });
                    return of();
                }),
                finalize(() => {
                    
                })
            )
            .subscribe();
    }

    deleteSupplier(id: string, companyName:string) {
        this.confirmationService.confirm({
            message: "You are about to delete '"+companyName+"'. Are you sure?",
            header: "Warning",
            icon: "pi pi-warning",
            acceptLabel: "Yes",
            rejectLabel: "No",
            accept: () => {
                this.supplierService
                    .deleteSupplier(this.token, id)
                    .pipe(
                        tap((res) => {
                            this.messageService.add({
                                severity: "success",
                                summary: "Success",
                                detail: res.message ?? "Success"
                            });
                        }),
                        catchError((res) => {
                            this.messageService.add({
                                severity: "error",
                                summary: "Error",
                                detail: res.error?.message ?? res.error.title
                            });
                            return of();
                        }),
                        finalize(() => {
                            this.getList(null);
                        })
                    )
                    .subscribe();
            },
            reject: () => {
                return;
            },
            rejectVisible: true
        }
        );
    }

    getList(event:LazyLoadEvent) {
        if(event != null)
            this.event_lazy_local = event;
        
        this.supplierListLoading = true;
        this.supplierService.getSupplierList(this.token, this.event_lazy_local)
            .pipe(
                tap((res) => {
                    if(res.data != null){
                        let slice = res.data.slice(this.event_lazy_local.first, (this.event_lazy_local.first + this.event_lazy_local.rows));
                        this.totalRecords = res.data.length;
                        this.supplierList = slice.map((supplier) => {
                            return {
                                id: supplier.id,
                                companyName: supplier.companyName,
                                guid: supplier.guid,
                                vatNumber: supplier.vatNumber,
                                fiscalCode: supplier.fiscalCode,
                                emailAddress: supplier.emailAddress,
                                country: supplier.country,
                                city: supplier.city
                            }
                        });
                        this.supplierListLoading = false;
                    }
                }),
                catchError((res) => {
                    this.messageService.add({
                        severity:'error',
                        summary:'Error',
                        detail: res.error?.message ?? res.error.title
                    })
                    return of();
                }),
                finalize(() => {

                })
            )
            .subscribe();
    }
}