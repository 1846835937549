import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LazyLoadEvent } from "primeng/api";
import { Observable } from "rxjs";
import { BaseRequestMethod } from "src/app/core/base/base.service";
import { BaseService } from "src/app/core/base/base.service";
import { ExportFileType } from "src/app/enum/exportFileType.enum";

@Injectable()
export class CollectionPlanService {
    
    constructor(private http: HttpClient, private baseservice: BaseService) {}

    getCollectionPlanList(token:string, event:LazyLoadEvent, serviceInstanceId:string):Observable<any>{
        return this.baseservice.callService<any>(BaseRequestMethod.POST, "collectionplans", token, {
            filtersCollectionsPlanList: {
                serviceInstanceId: serviceInstanceId ?? null
            },
            first:event?.first ?? null,
            globalFilter:event?.globalFilter ?? null,
            rows:event?.rows ?? null,
            end:null,
            sortField:event?.sortField ?? null,
            sortOrder:event?.sortOrder ?? null
        });
    }

    exportCollectionPlan(token:string, serviceInstanceId:string, collectionPlanId:string, typeExport:ExportFileType):Observable<any>{
        return this.baseservice.callService<any>(BaseRequestMethod.POST, 'collectionplans/export/collectionplans/file', token,
        {
            extension: typeExport,
            serviceInstanceId: serviceInstanceId,
            id: collectionPlanId
        });
    }

    exportCollectionPlanSimple(token: string, serviceInstanceId: string, collectionPlanId:string, typeExport:ExportFileType): Observable<any>{
        return this.baseservice.callService<any>(BaseRequestMethod.POST, 'collectionplans/export/collectionplans/file', token,
            {
                extension: typeExport,
                serviceInstanceId: serviceInstanceId,
                id: collectionPlanId,
                versionId: null,
                isCompleted: false
            });
    }
}