<div class="login-body">
    <div *ngIf="!finished">
        <div class="p-fl-container"></div>
        <div class="p-d-flex p-fl-spinner">
            <p-progressSpinner styleClass="custom-spinner"></p-progressSpinner>
        </div>
    </div>
    <div class="login-image">
        <div style="width:1000px;">
            <div style="padding:40vh 0px 0px 0px;width:50%;margin:0 auto;color:#fff;">
                <h1 style="font-size:60px;font-weight: 100;line-height: 60px;">Welcome to</h1>
                <h1 style="font-size:60px;color:#fff;line-height: 60px;"><span>Philo</span> <span style="font-weight: 100;">App</span></h1>
                <div class="separator"></div>
                <div class="login-page-logo">
                    <img src="assets/images/logo-alkenium.png">
                </div>
                <h3>Customer</h3>
            </div>
        </div>
    </div>
    <div class="login-panel p-fluid">
        <div class="p-d-flex p-flex-column container-column">
            <div class="p-d-flex p-ai-center logo-container">
                <h1 style="font-weight:100;color:#000; font-size: 27px;margin-bottom:20px;">Access the reserved area</h1>
            </div>
            <form #loginForm="ngForm" class="container-mid" (ngSubmit)="loginForm.form.valid && onLogin(loginForm)">
                <div class="form-container">
                    
                    <div class="mg-5-oris">
                        <span class="p-input-icon-left">
                            <input type="text" autocomplete="off" pInputText placeholder="Email" name="Email" [(ngModel)]="user.username" #username='ngModel' required>
                        </span>
                        <div *ngIf="loginForm.submitted && username.invalid" class="error-login-info">
                            <small *ngIf="username.errors?.required" class="p-error">Enter email</small>
                        </div>
                    </div>
                    <div class="mg-5-oris">
                        <span class="p-input-icon-left">
                            <input type="password" autocomplete="off" pInputText placeholder="Password" name="Password" [(ngModel)]="user.password" #password='ngModel' required>
                        </span>
                        <div *ngIf="loginForm.submitted && password.invalid" class="error-login-info">
                            <small *ngIf="password.errors?.required" class="p-error">Enter password</small>
                        </div>
                    </div>
                </div>
                <div class="p-d-flex p-jc-start">
                    <div class="button-container">
                        <button type="submit" class="button-container-login" pButton label="Login"></button>
                    </div>
                </div>
            </form>         
            
        </div>
        <div class="login-footer p-d-flex p-ai-center">
        </div>
    </div>
    <div style="z-index: 99999 !important;">
        <p-toast></p-toast>
    </div>
</div>